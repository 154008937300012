import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import '../styles/main.scss'

const Home = () => {
  return (
    <div id="page">
      <main>
        <section id="hero">
          <div className="container">
            <h1>Ludwig Family Reunion</h1>
            <p>June 30, 2022 - July 4, 2022</p>
            <p>1650 Pleasant Valley Rd, Marion, MT 59925</p>
            <div id="hero-photo">
              <StaticImage 
                src="../images/ludwig-siblings.jpg" 
                alt="The ludwig siblings" 
                placeholder="blurred"
                width={1040} />
              <p>Ludwig Reunion 1980 or 1985</p>
            </div>
          </div>
        </section>
        <section id="introduction">
          <div className="container two-columns">
            <div>
              <p>The Ludwig Family Reunion will be held Thursday, June 30 through Monday, July 4, 2022 at the Bitterroot Lions Youth Camp and Campground located at 1650 Pleasant Valley Rd, Marion, MT 59925.</p>
              <p>Join us for hearty laughs, good food, campfires, water fun, karaoke, live and silent auctions, and so much more. There will not only be activities for the little kids, but also many fun things for big kids including a golf tournament, white-water rafting, basketball and volleyball tournaments, horseshoes, and of course, cornhole!</p>
            </div>
            <div>
              <p>The cost is $50 per person. Kids 13 and under are free!</p>
              <p>Register online to let us know you’re coming and reserve a cabin or camping spot if needed. Cabins and RV spots are reserved on a first come, first serve basis when fees are paid in full.</p>
            </div>
          </div>
        </section>
        <div className="border-bottom"></div>
        <section id="register">
          <div className="container two-columns">
            <div>
              <h2>Register</h2>
              <p>The cost is $50 per person. Kids 13 and under are free!</p>
            </div>
            <div>
              <div className="register-instructions">
                <div>1</div>
                <div><a href="https://forms.gle/3KrmWzRj17W9n4kGA">Fill out the Google Form</a> to let us know you are coming.</div>
              </div>
              <div className="register-instructions">
                <div>2</div>
                <div>Finalize your registration by <a href="https://checkout.square.site/buy/HWS56PFIEG3G4UO4NFPFEQUN" target="_blank">submitting payment by debit/credit card</a> or check/cash. Cabins and RVs are given to those on a first come, first serve basis when fees are paid in full.</div>
              </div>
              <div className="register-instructions">
                <div>3</div>
                <div><a href="https://www.signupgenius.com/go/10c0f4baaa92fa1fbcf8-kitchen" target="_blank">Volunteer for a kitchen shift</a> and check out the <a href="#events">schedule of events</a>.</div>
              </div>
            </div>
          </div>
        </section>
        <div className="border-bottom"></div>
        <section id="kitchen">
          <div className="container two-columns">
            <div>
              <h2>Volunteer</h2>
            </div>
            <div>
              <p>It takes a village to make this reunion run smoothly. When you register, please sign up to help in the kitchen and/or manage one of the many activities. The more the work is spread around, the more fun we can all have!</p>
              <a href="https://www.signupgenius.com/go/10c0f4baaa92fa1fbcf8-kitchen" target="_blank">Volunteer →</a>
            </div>
          </div>
        </section>
        <div className="border-bottom"></div>
        <section id="events">
          <div className="container two-columns">
            <div>
              <h2>Events</h2>
              <p>Check back in as more events will be added over the next few months.</p>
            </div>
            <div>
              <h3>Thursday June 30</h3>
              <p>10:00 am Check-in and Registration</p>
              <p>05:00 pm - 06:30 pm Dinner</p>
              <h3>Friday July 01</h3>
              <p>08:00 am - 09:00 am Breakfast</p>
              <p><a href="https://www.signupgenius.com/go/10C0F4BAAA92FA1FBCF8-2022">TBD White Water Rafting</a></p>
              <p>12:00 pm - 01:00 pm Lunch</p>
              <p>01:00 pm - 03:00 pm Volleyball</p>
              <p>01:00 pm - 03:00 pm Basketball</p>
              <p>01:00 pm - 03:00 pm 3 Legged Race</p>
              <p>01:00 pm - 03:00 pm Bake Sale</p>
              <p>02:00 pm - 04:00 pm Slip n Slide</p>
              <p>03:00 pm - 05:00 pm Cornhole</p>
              <p>05:00 pm - 07:00 pm Dinner</p>
              <p>07:00 pm - 09:00 pm Poker</p>
              <p>07:00 pm - 09:00 pm Bunko</p>
              <p>07:00 pm - 09:00 pm Karaoke</p>
              <h3>Saturday July 02</h3>
              <p>08:00 am - 09:00 am Breakfast</p>
              <p>010:00 am - 12:00 pm Fun Run</p>
              <p><a href="https://www.signupgenius.com/go/10C0F4BAAA92FA1FBCF8-20221">TBD Golf Tournament</a></p>
              <p>12:00 pm - 01:00 pm Lunch</p>
              <p>01:00 pm - 03:00 pm Tie Dye</p>
              <p>01:00 pm - 03:00 pm Chalk</p>
              <p>01:00 pm - 03:00 pm Rock Art</p>
              <p>01:00 pm - 03:00 pm Obstacle Course</p>
              <p>02:00 pm - 04:00 pm Slip n Slide</p>
              <p>03:00 pm - 05:00 pm Kickball</p>
              <p>03:00 pm - 05:00 pm Horseshoes</p>
              <p>03:00 pm - 05:00 pm Tug of War</p>
              <p>05:00 pm - 07:00 pm Dinner</p>
              <p>07:00 pm - 09:00 pm Live Auction</p>
              <h3>Sunday July 03</h3>
              <p>08:00 am - 09:00 am Breakfast</p>
              <p>12:00 pm - 01:00 pm Lunch</p>
              <p>01:00 pm - 01:30 pm Anniversary Cake and Dance</p>
              <p>01:30 pm - 02:30 pm Canvas Handprint</p>
              <p>02:00 pm - 03:00 pm Sports Clinic</p>
              <p>02:00 pm - 04:00 pm Slip n Slide</p>
              <p>03:00 pm - 05:00 pm Dodgeball</p>
              <p>05:00 pm - 07:00 pm Dinner</p>
              <p>07:00 pm - 09:00 pm Projector Movie</p>
              <h3>Monday July 04</h3>
              <p>08:00 am - 09:00 am Breakfast</p>
              <p>09:00 pam - 02:00 pm Move Out</p>
            </div>
          </div>
        </section>
        <div className="border-bottom"></div>
        <section id="contact">
          <div className="container">
            <h2>Contact The Organizers</h2>
            <p>You can contact us by emailing <a href="mailto:ludwigreunion250@gmail.com">ludwigreunion250@gmail.com</a>.</p>
            <div className="three-columns">
              <div>
                <p className="contact-name">Tim Little</p>
                <p className="contact-job">Live and silent auction</p>
                <p className="contact-phone"><a href="tel:4254450340">(425) 445-0340</a></p>
              </div>
              <div>
                <p className="contact-name">Tracee Gallo</p>
                <p className="contact-job">Registration</p>
                <p className="contact-phone"><a href="tel:4064650193">(406) 465-0193</a></p>
              </div>
              <div>
                <p className="contact-name">Shelby Abrahansom</p>
                <p className="contact-job">Activities director</p>
                <p className="contact-phone"><a href="tel:5099399760">(509) 939-9760</a></p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer></footer>
    </div>
  )
}

export default Home